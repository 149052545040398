import(/* webpackMode: "eager", webpackExports: ["HighlightInit"] */ "/vercel/path0/node_modules/@highlight-run/next/dist/next-client.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-heading\"}],\"variableName\":\"fontHeading\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-body\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/Progress.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/vercel/path0/src/components/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/shadcn/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
